<template>
    <div class="content">
        <h1 class="pagetitle">
            {{ management.title }}{{ textset[management.textset][editMode] }}：確認
        </h1>
        {{$store.getters.getInput}}
        <table :class="$style.confirmTable">
            <template v-for="(data, index) in collection">
                <tr :key="`row${index}head`" :class="$style.confirmTable__row">
                    <th :class="$style.confirmTable__row__head">
                        {{ field[data.field].head }}
                    </th>
                </tr>
                <tr :key="`row${index}data`" :class="$style.confirmTable__row">
                    <td :class="$style.confirmTable__row__data">
                        {{ input[data.field] }}
                    </td>
                </tr>
            </template>
        </table>
        <div class="buttonArea">
            <div>
                <Button
                    :text="buttonText"
                    type="next"
                    @action="buttonNext()"
                />
            </div>
            <div>
                <Button
                    text="入力へ戻る"
                    type="textlink"
                    @action="routeBack()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    "use strict";

    import confirmSave from "@/mixins/confirmSave.js";
    import detail from "@/mixins/detail.js";

    export default {
        "mixins": [
            confirmSave,
            detail,
        ],
        "props": {
            "editMode": {
                "type": String,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "id": "",
                "input": {},
            };
        },
        "computed": {
            buttonText() {
                let text = "";
                if (this.editMode === "create") {
                    text = "登録する";
                }
                else if (this.editMode === "update") {
                    text = "変更する";
                }
                return text;
            },
        },
        created() {
            this.$_reset();
            this.$_setData();
            if (this.$store.getters.getTransceiver.input) {
                this.id = this.$store.getters.getTransceiver.id;
                this.input = this.$store.getters.getTransceiver.input;
            }
            else {
                this.$router.back();
            }
        },
        "methods": {
            routeBack() {
                if (this.editMode === "create") {
                    this.$router.push({
                        "name": `${this.name}_create_input`,
                    });
                }
                else if (this.editMode === "update") {
                    this.$router.push({
                        "name": `${this.name}_update_input`,
                        "params": {
                            "id": this.id,
                        },
                    });
                }
            },
            buttonNext() {
                const data = {};
                if (this.editMode === "create") {
                    this.$_create().then(() => {
                        this.$store.dispatch("setTransceiver", data);
                        this.$router.push({
                            "name": `${this.name}_create_complete`,
                        });
                    }).catch(error => {
                        console.error(error.message);
                        alert("登録に失敗しました。");
                        this.$store.dispatch("setTransceiver", data);
                    });
                }
                else if (this.editMode === "update") {
                    this.$_update(this.id).then(() => {
                        this.$store.dispatch("setTransceiver", data);
                        this.$router.push({
                            "name": `${this.name}_update_complete`,
                        });
                    }).catch(error => {
                        console.error(error.message);
                        alert("変更に失敗しました。");
                    });
                }
            },
        },
    };
</script>

<style lang="scss" module>
    .confirmTable {
        border-top: 1px solid #ddd;
        &__row {
            &__head,
            &__data {
                background: #fff;
                border-bottom: 1px solid #ddd;
                line-height: 1.6;
                padding: 12px;
                vertical-align: top;
            }
            &__head {
                background: #f5f5f5;
                font-weight: bold;
            }
        }
    }
</style>
